(function() {
    'use strict';

    // NOTE this is a dupe of OLTSResultsController. Summary results are differentiated but detail results are not

    angular.module("aerosApp")
        .controller("CertificationResultsCtrl", CertificationResultsController);

    CertificationResultsController.$inject =  ["$scope", "aerosApi", "$state", "$stateParams", "$uibModal",
        "configService", "wavelengths", "$timeout", "$rootScope", "projectSummary", "TestResultsService", "project",
        "routesConstant", "PageService", "UserProfileService", "UnitsMeasurementService"];

    function CertificationResultsController($scope, aerosApi, $state, $stateParams, $uibModal, configService,
                                            wavelengths, $timeout, $rootScope, projectSummary, TestResultsService,
                                            project, routesConstant, PageService, UserProfileService,
                                            UnitsMeasurementService) {

        angular.extend($scope, {
            getFiberStatus: getFiberStatus
        });

        var CUSTOMER = routesConstant.CUSTOMER;

        $scope.tool = $stateParams.testType;
        $scope.summary = projectSummary;

        var userProfile = UserProfileService.get();
        $scope.unit = UnitsMeasurementService.getUnit(userProfile.user.preferredUnitOfMeasure);

        if (!$scope.project || !$scope.project.fiberGroups) {
            $scope.project = project;
        }

        $scope.fiberGroups = projectSummary.fiberGroups.filter(toolTypeCriteria);

        $scope.resultType = "all";

        loadResults($stateParams.id);

        // $scope.resultType = $stateParams.resultType;
        $scope.testType = $stateParams.testType;
        $scope.formData = {
            queryString: $stateParams.qry
        };
        if ($stateParams.qry.substring(0, 3) === "id=") {
            $scope.formData.queryString = "";
            $scope.fibergroupId = $stateParams.qry.substring(3);
        }

        $scope.wavelengths = wavelengths;

        //Note: There is currently a copy of this and addUntestedFiberResults in the ReportController.
        //These should be combined at some point. However, until then make changes there too,
        function loadResults(projectId) {
            if (!$scope.allow['viewProject']) return $scope.reject();
            configService.getPromise().then(function (config) {
                aerosApi.loadProjectResults(projectId, $scope.organization.id)
                    .then(filterResultsByFG($scope.fiberGroups))
                    .then(function (data) {
                        var testMethods = config.getTestMethods();
                        $scope.results = data;
                        addUntestedFiberResults($scope.results);
                        initBidirectional();
                        $scope.refactor = TestResultsService.refactorData(data,testMethods);
                    });
            });
        }

        function filterResultsByFG(fiberGroups) {
            return function (results) {
                return results.data.filter(function (item) {
                    return fiberGroups.find(function (fg) {
                        return fg.uuid === item.uuid;
                    });
                });
            }
        }

        $scope.iconMap = {
            "Failed": "times",
            "PassedOrComplete": "check",
            "Incomplete": "question"
        };

        $scope.showFiberGroupInfo = function (fg) {
            var fiberGroup = $scope.fiberGroupById[fg.id];
            $uibModal.open({
                size: "md",
                templateUrl: 'fiberGroupInfoModalCertification.html',
                controller: ["$scope", "fiberGroup", function ($scope, fiberGroup) {
                    $scope.fiberGroup = fiberGroup;
                }],
                resolve: {
                    fiberGroup: function () {
                        return fiberGroup;
                    }
                }
            });
        };

        $scope.showResultsDetails = function (fg,fiberId) {
            var fiberGroup = $scope.fiberGroupById[fg.id];
            var testResults = undefined;
            for( var h=0; h<$scope.refactor.length; h++) {
                if( $scope.refactor[h].uuid == fiberGroup.uuid ) {
                    testResults = $scope.refactor[h];
                    h=$scope.refactor.length;
                }
            }
            var refFiber = undefined;
            if( testResults != undefined ) {
                for( var i=0; i< testResults.refFibers.length; i++) {
                    if( testResults.refFibers[i].fiberId == fiberId ) {
                        refFiber = testResults.refFibers[i];
                        i=testResults.refFibers.length;
                    }
                }
            }
            if( refFiber != undefined ) {
                $uibModal.open({
                    size: "lg",
                    templateUrl: 'fiberGroupRefInfoModalOlts.html',
                    controller: ["$scope", "fiberGroup", "testResults", "refFiber", function ($scope, fiberGroup, testResults, refFiber) {
                        $scope.fiberGroup = fiberGroup;
                        $scope.testResults = testResults;
                        $scope.refFiber = refFiber;
                    }],
                    resolve: {
                        fiberGroup: function () {
                            return fiberGroup;
                        },
                        testResults: function () {
                            return testResults;
                        },
                        refFiber: function () {
                            return refFiber;
                        }
                    }
                });
            }
        };

        $scope.bidriectionalExists = false;

        function addUntestedFiberResults(results) {
            _.forEach(results, function (fiberGroup) {
                for (var i = fiberGroup.startFiber; i <= fiberGroup.fiberCount; i++) {
                    if (_.find(fiberGroup.testResults, {'fiberId': i.toString()}) == null) {
                        fiberGroup.testResults.push({fiberId: i, failedTests: [], status: 'Incomplete'});
                    }
                }
            });
        }

        $scope.fiberGroupById = {};

        function initBidirectional() {
            for (var i = 0; i < $scope.project.fiberGroups.length; i++) {
                var fiberGroup = $scope.project.fiberGroups[i];
                $scope.fiberGroupById[fiberGroup.id] = fiberGroup;
                if ($scope.bidriectionalExists === false) {
                    //check if the page has been filtered down to one fiber group's results
                    if ($stateParams.qry && fiberGroup.id !== $scope.fibergroupId) {
                        //this fiber group is not the one that has been filtered to
                        //jump out of the loop and go to the next fiber group
                        continue;
                    }
                    for (var j = 0; j < fiberGroup.testSetups.length; j++) {
                        var testSetup = fiberGroup.testSetups[j];
                        if (testSetup.direction === "Bidirectional") {
                            $scope.bidriectionalExists = true;
                            break;
                        }
                    }
                }
            }
        }

        $scope.isIncomplete = function (fiberGroup, result) {
            return fiberGroup.status == 'Incomplete';
        };

        $scope.hasRefResults = function (fg,fiberId) {
            var fiberGroup = $scope.fiberGroupById[fg.id];
            var testResults = undefined;
            for( var h=0; h<$scope.refactor.length; h++) {
                if( $scope.refactor[h].uuid == fiberGroup.uuid ) {
                    testResults = $scope.refactor[h];
                    h=$scope.refactor.length;
                }
            }
            var refFiber = undefined;
            if( testResults != undefined ) {
                for( var i=0; i< testResults.refFibers.length; i++) {
                    if( testResults.refFibers[i].fiberId == fiberId ) {
                        refFiber = testResults.refFibers[i];
                        i=testResults.refFibers.length;
                    }
                }
            }
            return refFiber != undefined;
        };

        function hasAnyResultByType(results, type) {
            return _.some(results, function (result) {
                return getFiberStatus(result) === type;
            });
        }

        $scope.getFiberGroupStatus = function (fiberGroup) {
            if (hasAnyResultByType(fiberGroup.fibers, "Failed")) {
                return "Failed";
            } else if (hasAnyResultByType(fiberGroup.fibers, "Incomplete")) {
                return "Incomplete";
            } else {
                return "PassedOrComplete";
            }
        };

        $scope.showResult = function (fiberGroup, result, resultType) {
            if (resultType === 'all') {
                return true;
            }

            if (resultType === 'complete') {
                return (result.status == 'PassedOrComplete');
            }

            if (resultType === 'failed-any') {
                return result.status == 'Failed';
            }

            if (resultType === 'incomplete') {
                return result.status == 'Incomplete';
            }

            if (resultType === 'failed-length') {
                if (typeof result.failedLength === "boolean") return result.failedLength;
                return (result.lengthMeasurement != null) && result.lengthMeasurement.status == 'Failed';
            }

            if (resultType === 'failed-loss') {
                return _.some(result.lossMeasurements, function (lossMeasurement) {
                    return hasFailure(lossMeasurement.MainToRemote) || hasFailure(lossMeasurement.RemoteToMain);
                });
            }
            if (resultType === 'failed-loss-only') {
                return result.failedLoss;
            }
            if (resultType === 'failed-orl-only') {
                return result.failedORLLoss;
            }

            if (resultType === 'failed-inspection') {
                if (typeof result.failedInspection === "boolean") return result.failedInspection;
                var mainEfi = result.inspectionMeasurements.Main;
                var remoteEfi = result.inspectionMeasurements.Remote;
                if (mainEfi) {
                    if (mainEfi.status === 'Failed') {
                        return true;
                    }
                }
                if (remoteEfi) {
                    if (remoteEfi.status === 'Failed') {
                        return true;
                    }
                }
                return false;
            }

            return false;
        };

        function hasFailure(directedLoss) {
            return directedLoss != null && directedLoss.failedLimits.length > 0;
        }

        $scope.hasORLResults = function (result) {
            return !angular.equals({}, result.orlLossMeasurements);
        };

        $scope.filterFiberGroups = function (fiberGroup) {
            if ($scope.fibergroupId) {
                return fiberGroup.uuid === $scope.fibergroupId;
            }

            if ($scope.formData.queryString.length == 0) {
                return true;
            }

            return fiberGroup.name.indexOf($scope.formData.queryString) != -1;
        };

        $scope.showTestType = function (fiberGroup, testType) {
            return _.some(fiberGroup.testResults, function (result) {
                return !_.isEmpty(result[testType.toLowerCase() + "Measurements"]);
            });
        };
        // AEROS-1135 format data for re-designed OLTS screen
        $scope.hasAnyOltsResults = function (flatResults) {
            return _.some(flatResults.fibers, function (fiber) {
                //Is there a Length OR a wavelenght? ORL only may not have lengths.
                return !!fiber.lengthStatus || !_.isEmpty(fiber.wavelengths);
            });
        };

        $scope.showFiberGroup = function (queryString) {
            $state.go(CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName, {id: $scope.project.id, qry: queryString});
        };

        var toggleOptions = ["Show Bidirectional Loss", "Show Average Loss"];
        $scope.bidirectionalToggleLabel = toggleOptions[0];
        $scope.toggleBidirectionalView = function () {
            $scope.bidirectionalToggleLabel = toggleOptions[1 - toggleOptions.indexOf($scope.bidirectionalToggleLabel)];
        };
        $scope.deselectProjects();

        /* PAIRING DEMO */
        $scope.demoAction = "Show";
        var demoFGs = [
            {
                "id": "zgdhZmwtZGV2cmULEgdQcm9qZWN0IiQ3YmZmZGJjYy00Yjg2LTQ3N2EtYWE4MC01ZjZhZjk3ODE5ZmIMCxIKRmliZXJHcm91cCIkMTM3ZTEzMmItZWY5NS00MzU2LWJhYmItYmFiZGM5MmM2NzhiDA",
                "name": "TEST-before/after-Main",
                "fiberType": "SMF",
                "mpoPolarity": "None",
                "endNameMain": "Front",
                "endNameRemote": "Back",
                "pairMode": "Before/After",
                "testResults": [
                    {
                        "fiberId": "0",
                        "sortIndex": 0,
                        "status": "Failed",
                        "lossMeasurements": {},
                        "orlLossMeasurements": {},
                        "inspectionMeasurements": {
                            "Main": {
                                "Before": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                },
                                "After": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                }
                            }
                        },
                        "lastUpdated": "May 20, 2016 10:05:15 AM",
                        "failedLength": false,
                        "failedLoss": false,
                        "failedORLLoss": false,
                        "failedInspection": true,
                        "incomplete": true,
                        "needsImageUrlGeneration": false
                    }
                ]
            },
            {
                "id": "ygdhZmwtZGV2cmULEgdQcm9qZWN0IiQ3YmZmZGJjYy00Yjg2LTQ3N2EtYWE4MC01ZjZhZjk3ODE5ZmIMCxIKRmliZXJHcm91cCIkMTM3ZTEzMmItZWY5NS00MzU2LWJhYmItYmFiZGM5MmM2NzhiDA",
                "name": "TEST-Input/Output-Remote",
                "fiberType": "SMF",
                "mpoPolarity": "None",
                "endNameMain": "Upstairs",
                "endNameRemote": "Downstairs",
                "pairMode": "Input/Output",
                "testResults": [
                    {
                        "fiberId": "0",
                        "sortIndex": 0,
                        "status": "Failed",
                        "lossMeasurements": {},
                        "orlLossMeasurements": {},
                        "inspectionMeasurements": {
                            "Remote": {
                                "Before": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                },
                                "After": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                }
                            }
                        },
                        "lastUpdated": "May 20, 2016 10:05:15 AM",
                        "failedLength": false,
                        "failedLoss": false,
                        "failedORLLoss": false,
                        "failedInspection": true,
                        "incomplete": true,
                        "needsImageUrlGeneration": false
                    }
                ]
            },
            {
                "id": "xgdhZmwtZGV2cmULEgdQcm9qZWN0IiQ3YmZmZGJjYy00Yjg2LTQ3N2EtYWE4MC01ZjZhZjk3ODE5ZmIMCxIKRmliZXJHcm91cCIkMTM3ZTEzMmItZWY5NS00MzU2LWJhYmItYmFiZGM5MmM2NzhiDA",
                "name": "TEST-Jumper/Bulkhead-Main",
                "fiberType": "SMF",
                "mpoPolarity": "None",
                "endNameMain": "Day",
                "endNameRemote": "Night",
                "pairMode": "Jumper/Bulkhead",
                "testResults": [
                    {
                        "fiberId": "0",
                        "sortIndex": 0,
                        "status": "Failed",
                        "lossMeasurements": {},
                        "orlLossMeasurements": {},
                        "inspectionMeasurements": {
                            "Main": {
                                "Before": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                },
                                "After": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                }
                            }
                        },
                        "lastUpdated": "May 20, 2016 10:05:15 AM",
                        "failedLength": false,
                        "failedLoss": false,
                        "failedORLLoss": false,
                        "failedInspection": true,
                        "incomplete": true,
                        "needsImageUrlGeneration": false
                    }
                ]
            },
            {
                "id": "wgdhZmwtZGV2cmULEgdQcm9qZWN0IiQ3YmZmZGJjYy00Yjg2LTQ3N2EtYWE4MC01ZjZhZjk3ODE5ZmIMCxIKRmliZXJHcm91cCIkMTM3ZTEzMmItZWY5NS00MzU2LWJhYmItYmFiZGM5MmM2NzhiDA",
                "name": "TEST-As built/As found-Main_and_Remote",
                "fiberType": "SMF",
                "mpoPolarity": "None",
                "endNameMain": "Inside",
                "endNameRemote": "Outside",
                "pairMode": "As built/As found",
                "testResults": [
                    {
                        "fiberId": "0",
                        "sortIndex": 0,
                        "status": "Failed",
                        "lossMeasurements": {},
                        "orlLossMeasurements": {},
                        "inspectionMeasurements": {
                            "Main": {
                                "Before": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",
                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                },
                                "After": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                }
                            },
                            "Remote": {
                                "Before": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",

                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                },
                                "After": {
                                    "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                    "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                    "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                    "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",
                                    "standard": "IEC_61300_3_35_APC_SM",
                                    "status": "Failed",
                                    "failedLimits": [
                                        "PC,SM,ORL >= 45dB"
                                    ],
                                    "needsMainImageUrl": false,
                                    "needsOverlayImageUrl": false,
                                    "timestamp": "2016-05-20T10:05:15.435-04:00"
                                }
                            }
                        },
                        "lastUpdated": "May 20, 2016 10:05:15 AM",
                        "failedLength": false,
                        "failedLoss": false,
                        "failedORLLoss": false,
                        "failedInspection": true,
                        "incomplete": true,
                        "needsImageUrlGeneration": false
                    }
                ]
            },
            {
                "id": "vgdhZmwtZGV2cmULEgdQcm9qZWN0IiQ3YmZmZGJjYy00Yjg2LTQ3N2EtYWE4MC01ZjZhZjk3ODE5ZmIMCxIKRmliZXJHcm91cCIkMTM3ZTEzMmItZWY5NS00MzU2LWJhYmItYmFiZGM5MmM2NzhiDA",
                "name": "TEST-no pair-Main",
                "fiberType": "SMF",
                "mpoPolarity": "None",
                "endNameMain": "Start",
                "endNameRemote": "Finish",
                //"pairMode": "Jumper/Bulkhead",
                "testResults": [
                    {
                        "fiberId": "0",
                        "sortIndex": 0,
                        "status": "Failed",
                        "lossMeasurements": {},
                        "orlLossMeasurements": {},
                        "inspectionMeasurements": {
                            "Main": {
                                "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",
                                "standard": "IEC_61300_3_35_APC_SM",
                                "status": "Failed",
                                "failedLimits": [
                                    "PC,SM,ORL >= 45dB"
                                ],
                                "needsMainImageUrl": false,
                                "needsOverlayImageUrl": false,
                                "timestamp": "2016-05-20T10:05:15.435-04:00"
                            }
                        },
                        "lastUpdated": "May 20, 2016 10:05:15 AM",
                        "failedLength": false,
                        "failedLoss": false,
                        "failedORLLoss": false,
                        "failedInspection": true,
                        "incomplete": true,
                        "needsImageUrlGeneration": false
                    }
                ]
            }
        ];

        // TODO: inject as constant (from API?) - here and popup
        $scope.pairModes = {
            "Before/After": {
                "before": "Before",
                "after": "After"
            },
            "Input/Output": {
                "before": "Input",
                "after": "Output"
            },
            "Jumper/Bulkhead": {
                "before": "Jumper",
                "after": "Bulkhead"
            },
            "As built/As found": {
                "before": "As built",
                "after": "As found"
            }
        };


        $scope.togglePairDemo = function () {
            if ($scope.demoAction === "Show") {
                $scope.cacheResults = $scope.results;
                $scope.results = demoFGs;
                $scope.demoAction = "Hide";
            } else {
                $scope.results = $scope.cacheResults;
                $scope.demoAction = "Show";
            }
        };

        $scope.isToolApplied = function () {
            if ($stateParams.resultType !== 'all') {

                if (typeof $scope.summary !== 'undefined') {
                    var fb = $scope.fiberGroups.find(function (obj) {
                        return obj.uuid === $scope.fibergroupId;
                    });
                }

                return toolTypeCriteria(fb);
            } else {
                return typeof $scope.fiberGroups !== 'undefined' && $scope.fiberGroups;
            }
        };

        function toolTypeCriteria(fg) {
            return fg && fg.summaryResults && 'Certification' in fg.summaryResults;
        }

        $scope.isResultsExists = function () {
            if ($stateParams.resultType !== 'all') {

                if (typeof $scope.refactor !== 'undefined') {
                    var fb = $scope.refactor.find(function (obj) {
                        return obj.uuid === $scope.fibergroupId;
                    });
                }

                return typeof fb !== 'undefined' && fb.fibers.length > 0;
            } else {
                return typeof $scope.fiberGroups !== 'undefined' && $scope.fiberGroups;
            }
        };

        $scope.isShown = function () {
            if (typeof $scope.summary !== 'undefined') {
                var fb = $scope.fiberGroups.find(function (obj) {
                    return obj.uuid === $scope.fibergroupId;
                });
            }

            return fb && fb.summaryResults && 'Certification' in fb.summaryResults && !('OLTS' in fb.summaryResults);
        };

        function getFiberStatus(result) {
            if (result.failedORLLoss | result.failedLength | result.failedLoss) {
                return 'Failed';
            } else if (result.incompleteOLTS) {
                return 'Incomplete';
            }

            return 'PassedOrComplete';
        }

        PageService.setAflPageView(PageService.AFLPAGEVIEW.FIBERGROUPS_SUB, true);
    }

})();
